import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink } from '../../components';
import css from './InfoBoxWithLink.module.css';

const InfoBoxWithLink = (props) => {
    const {
        titleMsg,
        textMsg,
        linkMsg,
        linkName,
        className,
    } = props;

    return <div className={`${css.root} ${className}`}>
        {titleMsg ? <label className={css.mainLabel}>
            <FormattedMessage id={titleMsg} />
        </label> : null}

        <div className={css.box}>
            <FormattedMessage id={textMsg} />
            <NamedLink name={linkName}>
                <FormattedMessage id={linkMsg} />
            </NamedLink>
        </div>
    </div>
};

export default InfoBoxWithLink;
